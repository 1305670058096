$(function () {
    $(document).on('click', '.mobileQuoteLink a', function () {
        $('.header-mobile-quote .shortQuoteFormWrapper, .header-mobile-quote').toggleClass('showOnMobile');
        $('input[name="movingOption"][value="moving"]:visible').click();
        $(this).toggleClass('js-expanded');
    });
    $(document).on('click', '#close-nav', function () {
        $('.mobile-nav ul').removeClass('sfShown');
    });
    $(document).on(
        'top click',
        '.mobile-nav span.k-icon.k-i-arrow-s, .mobile-nav span.k-icon.k-i-arrow-60-down, .k-icon.k-i-arrow-e',
        function (e) {
            e.preventDefault();
            var childPages = $(this).parent().siblings('ul').children('li');
            if (childPages.is(':visible')) {
                childPages.toggleClass('js-mobile-closed');
                childPages.toggleClass('js-mobile-opened');
                $(this).css('background', 'url("/img/plus.png") 10px 10px no-repeat');
                childPages.parent().parent().removeClass('mobileSelected k-state-focused');
            } else {
                childPages.toggleClass('js-mobile-opened');
                childPages.toggleClass('js-mobile-closed');
                $(this).css('background', 'url("/img/minus.png") 10px 10px no-repeat');
                childPages.parent().parent().addClass('mobileSelected');
            }
        });

    $('footer .sfTxtBoxMedium input').attr('placeholder', 'Email Address');

    var googlePlus = '<li class="sshListItem googlePlus"><a href="http://plus.google.com/share?url=' + window.location.href + '"></a></li>';
    $('.RadSocialShare .sshContent').append(googlePlus);

    var mq = window.matchMedia('(max-width: 768px)');
    moveBlogSidebar(mq);
    mq.addListener(function (changed) {
        moveBlogSidebar(mq);
    });

    var blogCategories = $('.blog-categories');
    if (blogCategories.length > 0) {
        var category = getParameterByName('taxon').replace('/', '');
        var author = getParameterByName('Author');
        blogCategories.find('a').each(function () {
            var $this = $(this);
            var $href = $this.attr('href');
            if ($href) {
                if (category && $href.indexOf(category) > 0) {
                    $this.addClass('currentFilter');
                } else if (author && $href.indexOf(category) > 0 && $href.indexOf(author) > 0) {
                    $this.addClass('currentFilter');
                } else if ($href.indexOf(author) > 0 && $href.indexOf('taxon') == -1) {
                    $this.addClass('currentFilter');
                }
            }
        });
    }
});

function moveBlogSidebar(mq) {
    if (mq.matches) {
        $('.blog-contents').append($('.blog-sidebar'));
    } else {
        $('.blog-contents').parent('.sf_cols').prepend($('.blog-sidebar'));
    }
}

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(c_name) {
    if (document.cookie.length > 0) {
        c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}

function openChatWindow() {
    var chatWindowURL = "https://home-c20.incontact.com/inContact/ChatClient/ChatClient.aspx?poc=7e845b3d-ee56-402b-bd57-416618bca306&bu=4596017";
    var chatWindowName = "name";
    var chatWindowOptions = "toolbar=0,scrollbars=1,location=1,statusbar=0,menubar=0,resizable=0,width=500,height=600";
    window.open(chatWindowURL, chatWindowName, chatWindowOptions);
}

function hidePhoneNumbers() {
    $('header .header-phone a, footer .phone a').addClass('hide');
}

function showPhoneNumbers() {
    $('header .header-phone a, footer .phone a').removeClass('hide');
}

function populatePhoneNumbers(phoneNumber) {
    if (phoneNumber != undefined) {
        $('header .header-phone a, footer .phone a').text(phoneNumber.replace(/-/g, '.')).attr('href', 'tel:' + phoneNumber);
    }
    showPhoneNumbers();
}

if (!Array.from) { // This creates an Array.from function for browsers that don't have it, such as IE.
    Array.from = (function () {
        var toStr = Object.prototype.toString;
        var isCallable = function (fn) {
            return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
        };
        var toInteger = function (value) {
            var number = Number(value);
            if (isNaN(number)) { return 0; }
            if (number === 0 || !isFinite(number)) { return number; }
            return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
        };
        var maxSafeInteger = Math.pow(2, 53) - 1;
        var toLength = function (value) {
            var len = toInteger(value);
            return Math.min(Math.max(len, 0), maxSafeInteger);
        };

        // The length property of the from method is 1.
        return function from(arrayLike/*, mapFn, thisArg */) {
            // 1. Let C be the this value.
            var C = this;

            // 2. Let items be ToObject(arrayLike).
            var items = Object(arrayLike);

            // 3. ReturnIfAbrupt(items).
            if (arrayLike == null) {
                throw new TypeError("Array.from requires an array-like object - not null or undefined");
            }

            // 4. If mapfn is undefined, then let mapping be false.
            var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
            var T;
            if (typeof mapFn !== 'undefined') {
                // 5. else
                // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                if (!isCallable(mapFn)) {
                    throw new TypeError('Array.from: when provided, the second argument must be a function');
                }

                // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                if (arguments.length > 2) {
                    T = arguments[2];
                }
            }

            // 10. Let lenValue be Get(items, "length").
            // 11. Let len be ToLength(lenValue).
            var len = toLength(items.length);

            // 13. If IsConstructor(C) is true, then
            // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
            // 14. a. Else, Let A be ArrayCreate(len).
            var A = isCallable(C) ? Object(new C(len)) : new Array(len);

            // 16. Let k be 0.
            var k = 0;
            // 17. Repeat, while k < len… (also steps a - h)
            var kValue;
            while (k < len) {
                kValue = items[k];
                if (mapFn) {
                    A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                } else {
                    A[k] = kValue;
                }
                k += 1;
            }
            // 18. Let putStatus be Put(A, "length", len, true).
            A.length = len;
            // 20. Return A.
            return A;
        };
    }());
}